/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { Link, graphql, navigate } from 'gatsby';

import Layout from '../components/Layout';
import SubscriptionForm from '../components/SubscriptionForm';

import CONSTANTS from '../constants/common';

import IconArrowRight from '../assets/images/svg/arrow-right.inline.svg';
import IconArrowRightRed from '../assets/images/svg/arrow-right-red.inline.svg';

import '../assets/styles/pages/blog-page.scss';

const BlogPaginated = ({ data, pageContext: { hasMorePages, page } }) => {
  const featuredPost = data.featuredPost.edges;
  const allPosts = data.allPosts.edges;
  const popularPosts = data.popularPosts.edges;

  return (
    <Layout
      title="Barcelona Blog - Teaching English and Life in Barcelona"
      description="Weekly posts on life as an English teacher in Barcelona, including teaching tips, free events and informative articles about Catalan culture."
    >
      <div className="blog-page">
        <div className="blog__top">
          <div className="container">
            {featuredPost.map(({ node }, index) => (
              <div className="top" key={index}>
                {node?.data?.image && <img src={node.data.image.url} alt="" className="top__img" />}
                <div className="top__content">
                  <div className="content__author">
                    {node?.data?.author?.document?.data?.photo?.url && (
                      <img
                        src={node.data.author.document.data.photo.url}
                        alt="author"
                        className="author"
                      />
                    )}
                    <p>
                      <span className="author__name">
                        {node?.data?.author?.document?.data?.name?.text}
                      </span>{' '}
                      • <span className="date">{node?.data?.date}</span>
                    </p>
                  </div>
                  <div className="content__text">
                    <div className="title">{node?.data?.title?.text}</div>
                    <div className="description">{node?.data?.content?.text.slice(0, 309)}...</div>
                    <Link to={`/blog/${node.uid}`} className="c-btn c-btn--white">
                      Read More
                      <IconArrowRightRed />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="blog__main">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-8 order-1 order-lg-0">
                <div className="block_l">
                  {allPosts.map(({ node }, index) => (
                    <Link to={`/blog/${node.uid}`} className="post" key={index}>
                      {node?.data?.image && (
                        <img src={node.data.image.url} alt="imgPost" className="post__img" />
                      )}
                      <div className="post__author">
                        {node?.data?.author?.document?.data?.photo?.url && (
                          <img
                            src={node.data.author.document.data.photo.url}
                            alt="author"
                            className="author"
                          />
                        )}
                        <p>
                          <span className="author__name">
                            {node?.data?.author?.document?.data?.name?.text}
                          </span>{' '}
                          • <span className="date">{node?.data?.date}</span>
                        </p>
                      </div>
                      <div className="post__content">
                        <h3 className="content__title">{node?.data?.title?.text}</h3>
                        <p className="content__description">
                          {node?.data?.content?.text.slice(0, 250)}...
                        </p>
                      </div>
                    </Link>
                  ))}

                  <div className="block_l__btns">
                    {page - 1 > 0 && (
                      <Link
                        to={`/blog/page-${page - 1}`}
                        className="c-btn c-btn--red-border btn__prev"
                      >
                        <IconArrowRightRed /> Prev
                      </Link>
                    )}
                    {hasMorePages && (
                      <Link to={`/blog/page-${page + 1}`} className="c-btn c-btn--red btn__next">
                        Next <IconArrowRight />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-4">
                <div className="block_r">
                  <h3 className="block_r__title">Popular Posts</h3>
                  <div className="popular-posts">
                    {popularPosts.map(({ node }, index) => (
                      <Link to={`/blog/${node.uid}`} className="post" key={index}>
                        <div className="post__content">
                          <h5 className="post__title">{node?.data?.title?.text}</h5>
                          <p className="post__author">
                            {node?.data?.author?.document?.data?.name?.text} • {node?.data?.date}
                          </p>
                        </div>
                        {node?.data?.image && (
                          <img src={node.data.image.url} alt="imgPost" className="post__img" />
                        )}
                      </Link>
                    ))}
                  </div>
                  <SubscriptionForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query BlogPage($skip: Int!, $limit: Int!) {
    allPosts: allPrismicArticle(
      filter: { data: { featured_post: { eq: false } } }
      sort: { fields: data___date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            content {
              text
            }
            date(formatString: "MMMM, DD YYYY")
            image {
              url
            }
            author {
              document {
                ... on PrismicTeam {
                  id
                  data {
                    name {
                      text
                    }
                    photo {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    featuredPost: allPrismicArticle(
      filter: { data: { featured_post: { eq: true } } }
      limit: 1
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            content {
              text
            }
            date(formatString: "MMMM, DD YYYY")
            image {
              url
            }
            author {
              document {
                ... on PrismicTeam {
                  id
                  data {
                    name {
                      text
                    }
                    photo {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    popularPosts: allPrismicArticle(
      filter: { data: { popular_post: { eq: true } } }
      limit: 4
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            date(formatString: "MMMM, DD YYYY")
            image {
              url
            }
            author {
              document {
                ... on PrismicTeam {
                  data {
                    name {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default BlogPaginated;
